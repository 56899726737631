// @ts-nocheck
import { IEnvironment } from "./environment.interface";
import { LogLevel } from "@azure/msal-browser";

export const environment: IEnvironment = {
  production: 'true' === 'true',
  apiUrl: 'https://permitting-tool-api-bta.azurewebsites.net',
  clientId: '55cd4778-0bc7-406f-a170-149c6fdc06ac',
  tenant: '3a86ebff-1254-450e-bd5e-161fedab6b9a',
  msalLogLevel: LogLevel['Error'],
  maximoProxy: 'https://dw-inspections-mw-dev.azurewebsites.net/api/'
};
